import { React, createContext, useState } from 'react';

export const AppContext = createContext();

const AppProvider = ({ children }) => {
    const [formname, setFormName] = useState('');

    const setFormNameFn = (name, navigate) => {
        console.log(name)
        setFormName(name);
        if (typeof window !== 'undefined') {
            navigate('/mensaje-enviado');
        }
    }

    return (
        <AppContext.Provider
            value={{
                formname,
                setFormNameFn
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider;
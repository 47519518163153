// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-comentario-enviado-js": () => import("./../../../src/pages/comentario-enviado.js" /* webpackChunkName: "component---src-pages-comentario-enviado-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mensaje-enviado-js": () => import("./../../../src/pages/mensaje-enviado.js" /* webpackChunkName: "component---src-pages-mensaje-enviado-js" */)
}

